<template>
  <v-card class="widget-card"
          :color="color"
          max-width="210"
          dark
          outlined>
    <v-card-text class="pa-0">
        <div class="layout row ma-0">
          <div class="sm4 xs4 flex">
            <div class="layout column ma-0 justify-center align-center">
              <v-icon right size="76px" color="white" style="opacity: 0.8;">{{icon}}</v-icon>
            </div>
          </div>
          <div class="layout column ma-3 text-right" style="color: white;">
            <span class="caption">{{ subTitle }}</span>
            <div class="headline text-right font-weight-bold">{{ title }}</div>
            <span class="caption">{{ supTitle }}</span>
          </div>
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    supTitle: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false
    }
  },

  data() {
    return {
    }
  }
}
</script>

<style scoped>
  .widget-card {

  }
  .widget-card:hover{
/*
    opacity: 0.5;
*/
  }
</style>
